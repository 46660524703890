<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <div class="col-sm-12 my-5 px-4 text-center">
        <b-img
          src="/logo.png"
          fluid
          alt="FINavigator Logo"
          class="logo mx-auto"
        />
      </div>
      <div class="container user-register-page">
        <b-row class="justify-content-center">
          <b-col sm="12" md="12" lg="10">
            <b-card no-body class="mx-4">
              <b-card-header header-class="vendor-card">
                <h5 class="text-center"><b>TERMS OF SERVICE</b></h5>
              </b-card-header>
              <b-card-body>
                <TermsOfService></TermsOfService>
              </b-card-body>
              <b-card-footer class="p-3">
                <b-row>
                  <b-col cols="3"></b-col>
                  <div class="col-sm-6 text-left">
                    <div class="py-2">
                      <router-link to="/sign_in">
                        <b-button
                          block
                          class="btn"
                          variant="primary"
                          type="button"
                          ><span>Sign In</span></b-button
                        >
                      </router-link>
                    </div>
                  </div>
                  <b-col cols="3"></b-col>
                </b-row>
                <b-row>
                  <div class="col-sm-12 text-center">
                    <div class="py-2">
                      Don't have an account?
                      <router-link
                        to="/sign_up"
                        tag="span"
                        class="look-as-a-tag cursor-pointer"
                        >Request Access</router-link
                      >
                    </div>
                  </div>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
import TermsOfService from '@/modules/authentication/pages/terms_of_service'
export default {
  name: 'TermsOfServiceNoAuth',
  components: {
    TermsOfService
  },
  beforeCreate() {
    document.title = 'FI Navigator'
  }
}
</script>
